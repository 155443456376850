// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = window.STATIC_URL || '/';

import './vendor/bootstrap';

import Dropdown from 'bootstrap/dropdown';
import EventHandler from 'bootstrap/dom/event-handler';
import SelectorEngine from 'bootstrap/dom/selector-engine';

/**
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const EVENT_KEY = '.main';

const EVENT_MOUSEOVER = `mouseover${EVENT_KEY}`;

const SELECTOR_NAVBAR = '#navbar';
const SELECTOR_NAVBAR_CONTENT = '#navbarContent';
const SELECTOR_DATA_TOGGLE = '[data-bs-toggle="dropdown"]';
const SELECTOR_SCROLL_TOP = '.scroll-top';
const SELECTOR_SCROLL_TOP_BTN = '.scroll-top-btn';
const SELECTOR_DATA_MAP = '[data-map]';

/**
 * ------------------------------------------------------------------------
 * Application principale
 * ------------------------------------------------------------------------
 */

window.addEventListener('load', () => {
  document.documentElement.classList.remove('no-js');
});

//
// Bascule `.sticky` quand le menu principal est fixé en haut.
//

const navbar = SelectorEngine.findOne(SELECTOR_NAVBAR);

const navbarObserver = new IntersectionObserver(
  ([entry]) =>
    entry.target.classList.toggle('sticky', entry.intersectionRatio < 1),
  { rootMargin: '-1px 0px 0px 0px', threshold: [1] }
);
navbarObserver.observe(navbar);

//
// Enregistre la hauteur du menu pour compenser le scrolling
//
document.documentElement.style.setProperty(
  '--psm-menu-height',
  navbar.offsetHeight + 'px'
);

//
// Ouvre les sous-menus au survol, un à la fois uniquement.
//

const navbarContent = SelectorEngine.findOne(SELECTOR_NAVBAR_CONTENT);

EventHandler.on(
  navbarContent,
  EVENT_MOUSEOVER,
  SELECTOR_DATA_TOGGLE,
  function (event) {
    event.preventDefault();
    Dropdown.clearMenus(event);
    Dropdown.getOrCreateInstance(this).show();
  }
);

//
// Masque le bouton « haut de page » lorsqu'il est en dehors de l'écran.
//

const scrollTop = SelectorEngine.findOne(SELECTOR_SCROLL_TOP);
const scrollTopBtn = SelectorEngine.findOne(SELECTOR_SCROLL_TOP_BTN);

const scrollTopBtnObserver = new IntersectionObserver(
  ([entry]) =>
    scrollTop.classList.toggle('overflow-hidden', entry.intersectionRatio < 1),
  { threshold: [1] }
);
scrollTopBtnObserver.observe(scrollTopBtn);

//
// Charge les cartes dynamiquement.
//

const mapContainers = SelectorEngine.find(SELECTOR_DATA_MAP);

if (mapContainers.length) {
  import(/* webpackChunkName: "map" */ './components/map').then((module) => {
    const Map = module.default;

    mapContainers.forEach((element) => new Map(element));
  });
}

// sommaire éventuel
const sommaire = document.getElementById('sommaire');
if (sommaire) {
  for (const title of document.querySelectorAll('h2, h3')) {
    const link = document.createElement('a');
    link.appendChild(document.createTextNode(title.innerText));
    link.setAttribute('href', '#'.concat(title.id));
    console.log(typeof title.nodeName);
    if (title.nodeName === 'H3') {
      link.classList.add('ps-3');
    }

    sommaire.appendChild(link);
    sommaire.appendChild(document.createElement('br'));
  }
}